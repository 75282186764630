import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, ContentNode as TContentNode } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ContentEntityIntro, ContentNode, IconTextValue, useLang } from '@shapeable/ui';
import { AiLowerCaseIconGlyph } from '@shapeable/icons';
const cls = classNames('explorer-gpt--banner-layout');

// -------- Types -------->

// -------- Props -------->

export type ExplorerGptBannerLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerGptBannerLayoutDefaultProps: Omit<ExplorerGptBannerLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const BannerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)} ${theme.UNIT(4)};
    border-top: 3px solid #B9D9DA;
    border-radius: 0px 0px 8px 8px;
    background: ${theme.COLOR('app-chrome')};
  `,
});

const IconHeaderStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(32)};
    padding-bottom: ${theme.UNIT(2)};
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: ${14/13}em;
    color: ${theme.COLOR('text')};
    line-height: 1.5em;
    font-weight: 300;
  `,
});

// -------- Components -------->

const My = {
  Banner: styled.div<ContainerProps>`${BannerStyles}`,
  IconHeader: styled(IconTextValue)`${IconHeaderStyles}`,
  Intro: styled(ContentNode)`${IntroStyles}`,
};

export const ExplorerGptBannerLayout: Shapeable.FC<ExplorerGptBannerLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const t = useLang();

  const intro: TContentNode = {
    text: "Welcome to SynapseAI, trained on the sustainability and innovation content within this platform. How can I help? Below, you can ask a question or select a template to generate specific outputs."
  };
  
  return (
    <My.Banner>
      <My.IconHeader colors={{ text: 'strong' }} iconSize={55} iconComponent={AiLowerCaseIconGlyph}>{t('SynapseAI')}</My.IconHeader>
      <My.Intro entity={intro} />
    </My.Banner>
  )
};

ExplorerGptBannerLayout.defaultProps = ExplorerGptBannerLayoutDefaultProps;
ExplorerGptBannerLayout.cls = cls;