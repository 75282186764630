const gql = String.raw;
  
export const GlobalRegion = gql`
query AllGlobalRegionsQuery {
  
    allGlobalRegions {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubbleMedium { id url url2x }
                  }
                  
                } 
              }
            
      
      abbreviation
    }
  
}

`;
