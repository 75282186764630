import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, ContentNode as TContentNode, ConversationChannel } from '@shapeable/types';
import { breakpoints, theme, ColorTokens } from '@shapeable/theme';
import { classNames, entityTypeNameFor } from '@shapeable/utils';
import { CellLabel, ContentNode, EntityAsideMenu, entityAsideMenuKey, EntityValue, ExplorerAsideSection, UiComponentName, useComponentProps, useLang, useLink, useTrends, useView } from '@shapeable/ui';
import { ExplorerView } from 'types';

const cls = classNames('entity-news-aside-layout');

// -------- Types -------->

// -------- Props -------->

export type ExplorerNewsAsideLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerNewsAsideLayoutDefaultProps: Omit<ExplorerNewsAsideLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

type ItemProps = {
  isActive: boolean;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(3)};
    padding-top: ${theme.UNIT(4)};
  `,
});


const PaddingStyles = breakpoints({
  base: css`
    padding-left: ${theme.UNIT(4)};
    padding-right: ${theme.UNIT(4)};
  `,
});

const OverviewStyles = breakpoints({
  base: css`
    font-weight: 400;
    font-size: 1.0em;
    
    line-height: 1.3em;
    color: ${theme.COLOR('text-mid')};
    ${PaddingStyles};
    padding-top: ${theme.UNIT(1)};
    padding-bottom: ${theme.UNIT(2)};
  `,
});

const LabelStyles = breakpoints({
  base: css`
    ${PaddingStyles};
    color: ${theme.COLOR('text-mid')};
  `,
});


const ItemsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});



// -------- Components -------->

const My = {
  NAME: 'ExplorerNewsAsideLayout' as UiComponentName,

  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Overview: styled(ContentNode)`${OverviewStyles}`,
    Label: styled(CellLabel)`${LabelStyles}`,
    Menu: styled(EntityAsideMenu)`${ItemsStyles}`,
};

export const ExplorerNewsAsideLayout: Shapeable.FC<ExplorerNewsAsideLayoutProps> = (props) => {
  const { className, children, entity } = useComponentProps(My.NAME, props, ExplorerNewsAsideLayoutDefaultProps) as ExplorerNewsAsideLayoutProps;
  
  const t = useLang();

  const overviewText: TContentNode = {
    text: "Select one of the Innovation Systems below to view it's newsfeed from around the world.",
  };

  const items = useTrends();
  const initialSubView = entityAsideMenuKey(items?.[0]);
  
  const { setActiveSubView, activeSubView } = useView('navigator', 'news' as string, initialSubView);

  const handleEntityClick = (entity: Entity) => {
    setActiveSubView(entityAsideMenuKey(entity));
  };

  return (
    <My.Container className={cls.name(className)}>
      <My.Label>{t("Latest News:")}</My.Label>
      <My.Overview entity={overviewText} />
      <My.Menu onItemClick={handleEntityClick} activeItemKey={activeSubView} items={items} />
      {children}
    </My.Container>
  )
};

ExplorerNewsAsideLayout.cls = cls;