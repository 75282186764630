const gql = String.raw;
  
export const Topic = gql`
query AllTopicsQuery {
  
    allTopics {
      
        
      
          
      
        
      
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
      
          
      
          
      
                openGraph { 
      
                  id title 
      
                  
      
                  image { 
      
                    id url url2x 
      
                    thumbnails { 
      
                      id bubble { id url url2x }
      
          card { id url url2x }
      
                    }
      
                    
      
                  } 
      
                }
      
              
      
        
        color {    id value veryLightValue lightValue darkValue veryDarkValue  }
        dataSetId
        outlineNumber
        trend { id slug }
        # horizons {
        #   id slug name path
        #   type {
        #     id slug name
        #   }
        # }
      
    }
  
}

`;
