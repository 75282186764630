import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Horizon, HorizonType } from '@shapeable/adsw-types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity } from '@shapeable/ui';
import { HorizonCard } from './horizon-card';
import { compact, sortBy } from 'lodash';
import { useHorizonTypes } from '../../hooks/use-horizon-types';
import { classNames } from '@shapeable/utils';
const cls = classNames('horizon-card-grid');

// -------- Types -------->

export type HorizonCardGridProps = Classable & HasChildren & { 
  items: Horizon[];
};

export const HorizonCardGridDefaultProps: HorizonCardGridProps = {
  items: []
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    align-items: flex-start;
    align-self: stretch;
  `,
});

const HorizonStyles = breakpoints({
  base: css`

  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Horizon: styled(HorizonCard)`${HorizonStyles}`,
};

export const HorizonCardGrid: Shapeable.FC<HorizonCardGridProps> = (props) => {
  const { className, children, items } = props;

  const horizonTypes = useHorizonTypes();

  const types = compact(items.map((item) => item?.type));
  const horizonYears = types.map((type: HorizonType) => type?.years );

  return (
    <My.Container className={cls.name(className)}>
    {
      sortBy(horizonTypes, 'years').map((type: HorizonType, index )=> {
        const isHorizon = horizonYears.includes(type?.years);
        const itemWithDescription = items.find((item) => item.type?.id === type.id);
        const description = itemWithDescription?.description || {text: ''};

        return (
            <My.Horizon key={type.id} entity={type} horizonNumber={index} description={description} hasTick={isHorizon}/>
        )
      })
    }
    {children}
    </My.Container>
  )
};
HorizonCardGrid.defaultProps = HorizonCardGridDefaultProps;
HorizonCardGrid.cls = cls;