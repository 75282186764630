import { compact } from "lodash";
import { useEconomies, useOrganisations, useOrganisationTypes, usePeople, useSubTopics, useTopics, useTrends } from "@shapeable/ui";
import { sortBy } from "lodash";
import { Economy, Organisation, Person, Topic, Trend } from "@shapeable/adsw-types";
import { OrganisationType } from "@shapeable/types";

export const useCommunityFilters = () => {
  const economies = useEconomies();
  const organisationTypes = useOrganisationTypes();
  const organisations = useOrganisations();
  const people = usePeople();
  const topics = useTopics();
  const trends = useTrends();

  const filterOrgTypes = (entity: Organisation[], types: OrganisationType[]) => {
    return types.filter(type => 
      entity.some(org =>
        org?.type?.slug === type.slug
      ));
  };
  const filterOrgCountries = (entity: Organisation[] | Person[], countries: Economy[]) => {
    return countries.filter(country => 
      entity.some(org =>
        org?.country?.slug === country.slug
    ));
  };
  const filterOrgTrends = (entity: Organisation[] | Person[], trend: Topic[]) => {
    return trend.filter(trendObj => 
      entity.some(org =>
        org?.trends?.map(trend => trend.slug === trendObj.slug)
    ));
  };
  const filterOrgTopics = (entity: Organisation[] | Person[], topic: Topic[]) => {
    return topic.filter(topicObj => 
      entity.some(org =>
        org?.topics?.map(topic => topic.slug === topicObj.slug)
    ));
  };

  const allItems = [...people, ...organisations];

  const finalOrgTypes = filterOrgTypes(allItems, organisationTypes);
  const finalOrgCountries = filterOrgCountries(allItems, economies);
  const finalOrgTrends = filterOrgTrends(allItems, trends);
  const finalOrgTopics = filterOrgTopics(allItems, topics);

  return {
    selects: compact([
      !!finalOrgTypes.length &&
      {
        name: 'types',
        items: sortBy(finalOrgTypes, 'name'),
        pluralLabel: 'Stakeholder Types',
        getResultFieldSlugs: (result: Organisation) => [result.type?.slug],
      },
      !!finalOrgCountries.length &&
      {
        name: 'countries',
        items: sortBy(finalOrgCountries, 'name'),
        pluralLabel: 'Countries',
        getResultFieldSlugs: (result: Organisation | Person) => [result.country?.slug],
      },
      !!finalOrgTrends.length &&
      {
        name: 'trends',
        items: sortBy(finalOrgTrends, 'name'),
        pluralLabel: 'Innovation System',
        getResultFieldSlugs: (result: Organisation | Person) => result?.trends?.map(trend => trend.slug),
      },
      !!finalOrgTopics.length &&
      {
        name: 'topics',
        items: sortBy(finalOrgTopics, 'name'),
        pluralLabel: 'Focus Areas',
        getResultFieldSlugs: (result: Organisation | Person) => result.topics?.map(topic => topic.slug),
      },
    ]),
    typeToggles: [
      { name: 'Organisation' },
      { name: 'Person' },
      // { name: 'Partner' },
    ]
  };
};