import React from 'react';
import styled, { css } from 'styled-components';
import { ChatAutoMessagePrompt, Classable, Entity, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { SiteFooterProvider, SiteHeaderLayout, ExplorerEntityDetailsLayout, useEntity, ChatProvider, useLang, ExplorerProviders } from '@shapeable/ui';
import { useNavigatorMap } from '../../hooks/use-navigator-map';
import { NavigatorMap } from '../elements/navigator-map';
import { ExplorerGptBannerLayout } from './explorer-gpt-banner-layout';
import { ExplorerGptViewLayout } from '@shapeable/gpt';
// import { ExplorerGptViewLayout } from '@shapeable/gpt';
const cls = classNames('gpt-layout');

// -------- Types -------->

export type ExplorerGptLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerGptLayoutDefaultProps: Omit<ExplorerGptLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    background: ${theme.COLOR('canvas')};
  `,
});

const SiteHeaderStyles = breakpoints({
  base: css`
    
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,

});

const DataVizContainerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5, 2)} ${theme.UNIT(6)} ${theme.UNIT(6)} ${theme.UNIT(6)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${SiteHeaderStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout).attrs(cls.attr('Layout'))`${LayoutStyles}`,
    DataVizContainer: styled.div`${DataVizContainerStyles}`,
    
};

export const ExplorerGptLayout: Shapeable.FC<ExplorerGptLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { isShown } = useNavigatorMap();
  const t = useLang();

  const autoPrompts: ChatAutoMessagePrompt[] = [
    {
      id: 'adsw',
      type: 'text',
      values: { question: "Tell me about Abu Dhabi Sustainability Week" },
    },
    {
      id: 'net-zero-navigator', 
      type: 'text',
      values: { question: t("Tell me more about the Net Zero Navigator") },
    },
    {
      id: 'purpose',
      type: 'text',
      values: { question: "What is the purpose of the Net Zero Navigator?" },
    },
  ];

  return (
    <ChatProvider>
    <ExplorerProviders>
    <My.Container className={cls.name(className)}>
      <My.Layout
        entity={entity}
        asideMaxWidth={700}
        isAsideSticky={true}
        stickyTopOffset={110}
        asideIsRevealed={isShown}

        //main
        banner={
          <ExplorerGptBannerLayout entity={entity} />
        }
        content={
          <ExplorerGptViewLayout entity={entity} />
        }

        //aside
        dataViz={
          <My.DataVizContainer>
            <NavigatorMap />
          </My.DataVizContainer>
        }
      />
      {children}
    </My.Container>
    </ExplorerProviders>
    </ChatProvider>
   
  )
};

ExplorerGptLayout.defaultProps = ExplorerGptLayoutDefaultProps;
ExplorerGptLayout.cls = cls;