const gql = String.raw;
  
export const Citation = gql`
query AllCitationsQuery {
  
    allCitations {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
      type { name id }
      topics { id name slug path __typename _schema { label pluralLabel } }
      trends { id name slug path __typename _schema { label pluralLabel } }
    }
  
}

`;
