const gql = String.raw;
  
export const Topic = gql`
query TopicBySlugQuery($slug: ID!) {
  topicBySlug(slug: $slug) {
    
      
    
        id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
    
      
      
            openGraph { 
              id title 
              description { plain text }
              image { 
                id url url2x 
                thumbnails { 
                  id bubbleLarge { id url url2x }
      mainBanner { id url url2x }
      halfBanner { id url url2x }
                }
                
              } 
            }
          
      
        entityType { useAi }
        entityViews {
          id name hash label slug count showCount
          type { id name slug }
          slices {
            id name slug label updated
            title 
            titleRich { id text }
            layout { id name slug component }
            themeMode { id name slug }
            openGraph { 
              image { url thumbnails { large { url } bubble { url url2x } } }
            }
            emptyTout { id text }
            ownEmptyTout { id text }
            entityFormFields {
              name
              label
              description
              ownDescription
              type
              formFieldType
              entityTypeName
              entityTypeNamePlural
            }
            headerFontType { internalName }
            bodyFontType { internalName }
            disabled
            connectedEntities { name label internalName }
            entityLayout { internalName }
            created updated
            outro { id text }
            intro { id text }
            content { id plain text }
            email
            phone
            twitter
            threads
            linkedin
            backgroundColor { id value }
            color { id value }
            teams { id name slug __typename _schema { label pluralLabel } }
            objectives { id name slug __typename type { id name slug __typename } }
            icon { id slug name component }
            people {
              id name slug path __typename
              organisation { id name }
              linkedin
              position
              openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
            }
            indicators { 
              id name slug title
            }
            indicatorGroups { 
              id slug name 
            }
            economies {
              id name slug path __typename _schema { label pluralLabel }
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            } 
            locations { 
              id name slug __typename _schema { label pluralLabel } 
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            }
            posts { 
              id name slug path __typename _schema { label  pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            }
            feedEntries {
              name id slug path url __typename _schema { label pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { title image { url thumbnails { full { url } } } }
            }
            pages {
              id name slug path __typename _schema { label pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            }
            organisations { 
              id name slug path __typename _schema { label pluralLabel }
              openGraph { image { url url2x thumbnails { bubble { url url2x } card { url url2x } } } }
            }
            topicExpertise {
              __typename typeLabel badge name slug
              levelOfExpertise { name slug }
              topics { __typename outlineNumber id name slug path 
                openGraph {
                  title description { plain }
                  image { url url2x thumbnails { medium { url url2x } } }
                }
              }
            }
            trendExpertise {
              __typename typeLabel badge name slug
              levelOfExpertise { name slug }
              trends { __typename outlineNumber id path name slug
                openGraph { title description { plain } image { url url2x thumbnails { medium { url url2x } } }
      }
              }
            }
          }
        }
      
      gptName
      outlineNumber
      color { __typename id slug name value }
      trend { id slug name __typename }
      intro { id text }
      description { id text }
      organisations { 
        id name slug
        type { id name slug }
        countries { id slug name path }
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      }
      embeds {
        citations {
          slug url name authors { id name slug } 
          authorShowsEtAl edition publication accessDate 
          startPage volume footnoteNumber year
        }
        imageAssets {
          id
          slug
          image {
            id url url2x width height 
          }
        }
      }
      
      video {
        id name url
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      color { id name slug value darkValue veryDarkValue }
      banner {
        id
        image {
          id
          url
          thumbnails {
            mainBanner { id url }
          }
        }
      }
      fileAssets {
        id name slug file { id extension formattedSize url typeName } __typename
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      citations {
        __typename _schema { label pluralLabel }
        id path slug url name path
        authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
      }
      trend {
        id name path slug __typename
        outlineNumber
        color { id name slug value }
      }
      feedEntries {
        __typename _schema { label pluralLabel }
        id name slug url path
        types { id name slug }
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      posts {
        __typename _schema { label pluralLabel }
        id name slug path __typename published
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        people { id name slug path }
      }
      subTopics {
        id name path outlineNumber slug __typename 
        color { id name value } 
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      moderators {
        __typename id name slug position
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        organisation { id name slug }
      }
    
    
    policyTrends { id text }
    fundingTrends { id text }
    partnershipTrends { id text }
    libraryIntro { id text }
    trendIntro { id text }
    stakeholdersIntro { id text }
    instrumentsIntro { id text }
    technologiesIntro { id text }
    organisations { 
      path __typename 
      technologies { id name slug }
    }
    technologies {
      id
      name
      slug
      __typename
      _schema {
        label
        pluralLabel
      }
      openGraph {
        image {
          id
          url
        }
      }
      description {
        id
        text
      }
      url
      feedEntries {
        __typename
        _schema {
          label
          pluralLabel
        }
        id
        name
        slug
        url
        path
        websiteName
      }
      organisations {
        id
        name
        slug
        url
        type {
          id
          name
          slug
        }
        technologies {
          id
          name
          slug
        }
        openGraph {
          image {
            thumbnails {
              bubble {
                url
                url2x
              }
              card {
                url
                url2x
              }
            }
          }
        }
        topics {
          id
          name
          slug
          path
        }
      }
      instruments {
        id
        name
        slug
        path
        __typename
        _schema {
          label
          pluralLabel
        }
      }
    }
    instruments {
      id
      name
      slug
      __typename
      _schema {
        label
        pluralLabel
      }
      openGraph {
        image {
          id
          url
        }
      }
      description {
        text
      }
      types {
        id
        name
        slug
        path
      }
    }
  }
}

`;
