import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, GlobalRegionsProvider as Provider } from '@shapeable/ui';

const GLOBAL_REGIONS_QUERY = graphql`
query PlatformAllGlobalRegionsQuery {
  
    platform { allGlobalRegions {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubbleMedium { id url url2x }
                  }
                  
                } 
              }
            
      
      abbreviation
    } }
  
}

`;

export const GlobalRegionsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(GLOBAL_REGIONS_QUERY), 'allGlobalRegions' ) }>{children}</Provider>;
}