const gql = String.raw;
  
export const Trend = gql`
query AllTrendsQuery {
  
    allTrends {
      
        
      
          
      
        
      
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
      
          
      
          
      
                openGraph { 
      
                  id title 
      
                  
      
                  image { 
      
                    id url url2x 
      
                    thumbnails { 
      
                      id bubble { id url url2x }
      
          card { id url url2x }
      
                    }
      
                    
      
                  } 
      
                }
      
              
      
        
        dataSetId
        outlineNumber
        color {    id value veryLightValue lightValue darkValue veryDarkValue  }
        posts { 
          id name slug path __typename _schema { label  pluralLabel }
          type { id name slug }
          typeLabel
          published
          openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
          economies {          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }                openGraph {            id title                       image {              id url url2x              thumbnails {                id bubbleMedium { id url url2x }             }                        }          }         }
        }
        feedEntries {
          
            
          
              id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
          
            
            
                  openGraph { 
                    id title 
                    
                    image { 
                      id url url2x 
                      thumbnails { 
                        id bubble { id url url2x }
            card { id url url2x }
                      }
                      
                    } 
                  }
                
          
          url type { id name slug } published author typeLabel
          economies {          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }                openGraph {            id title                       image {              id url url2x              thumbnails {                id bubbleMedium { id url url2x }             }                        }          }         }
        }
        topics { 
           
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel } 
          
          outlineNumber
          color {    id value veryLightValue lightValue darkValue veryDarkValue  }
          citations { id name slug path }
        }
      
    }
  
}

`;
