import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, entityTypeNameFor } from '@shapeable/utils';
// import { RadarChart } from '@shapeable/gesda-ui';
import { DarkMode, DataVizMobileMain, ExplorerProviders, ExplorerTabs, SiteHeaderLayout, SiteHeaderProvider, useEntity, useEntityCommentsQuery } from '@shapeable/ui';
import { BackButtonEntityProvider, ComponentPropsProvider, ExplorerEntityDetailsLayout, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayoutProps, SliceLayoutBoundary } from '@shapeable/ui';
import { Trend } from '@shapeable/adsw-types';
import { NavigatorMap } from '../elements/navigator-map';
import { TrendTabsLayout } from './trend-tabs-layout';
import { TrendViewLayout } from './trend-view-layout';
import { DonutNavigatorIconGlyph } from '@shapeable/icons';
const cls = classNames('trend-explorer-layout');

// -------- Types -------->

// -------- Props -------->

export type TrendExplorerLayoutProps = ExplorerEntityDetailsLayoutProps & { 
  entity?: Trend;
};

export const TrendExplorerLayoutDefaultProps: Omit<TrendExplorerLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    .shp--entity-banner {
      h2.shp--entity-value__name {
      font-size: ${theme.FONT_SIZE(35)};
      }
    }
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const TabsStyles = breakpoints({
  base: css`
  `,
});

const DataVizContainerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5, 2)} ${theme.UNIT(6)} ${theme.UNIT(6)} ${theme.UNIT(6)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Tabs: styled(ExplorerTabs)`${TabsStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
      DataVizContainer: styled.div`${DataVizContainerStyles}`,

    

    
};

export const TrendExplorerLayout: Shapeable.FC<TrendExplorerLayoutProps> = (props) => {
  const { className, children, entity, loading } = props;
  
  if (loading) {
    return null;
  }
  
  return (
    <ExplorerProviders>
    <ComponentPropsProvider value={{
      EntityBanner: {
        maxHeight: 250,
        variant: 'overlay',
        showOverlay: true,
        customAspectRatio: { base: 16 / 9, desktop: 21 / 9 },
        entityValueProps: { showImage: false, showOutlineNumber: true, outlineBubble: { numberSize: 0.6, showBorder: true, showImage: false }}
      },
    }}>
    <My.Container>
      <SiteHeaderProvider value={{ variant: 'default' }}>
        <My.Header />
      </SiteHeaderProvider>
      <My.Tabs useStickyStyle />
      <SliceLayoutBoundary boundary='none' >
      <My.Layout 
        asideMaxWidth={700}
        entity={entity}
        stickyTopOffset={110}
        className={cls.name(className)}
        isAsideSticky={true}
        tabs={
          <TrendTabsLayout entity={entity} />
        }
        content={
          <TrendViewLayout entity={entity} />
        }

        dataVizMain={
          <DataVizMobileMain isExpanded label="Synapse Navigator Map" icon={DonutNavigatorIconGlyph} >
            <NavigatorMap />
          </DataVizMobileMain>
        }

        dataViz={
          <My.DataVizContainer>
            <NavigatorMap />
          </My.DataVizContainer>
        }
        />
        </SliceLayoutBoundary>
    </My.Container>
    </ComponentPropsProvider>
    </ExplorerProviders>
  )
};

TrendExplorerLayout.defaultProps = TrendExplorerLayoutDefaultProps;
TrendExplorerLayout.cls = cls;