const gql = String.raw;
  
export const Page = gql`
query AllPagesQuery {
  
    allPages {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      parent { id path slug name }
      types { id name }
      intro { id text }
      slices {
        id name slug path
        layout { id name slug component }
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        images {
          id name slug
          image { 
            id url url2x type width height 
          }
        }
      }
    }
  
}

`;
