import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Trend } from '@shapeable/adsw-types';
import { CellLabel, CitationCard, ContentEntityIntro, ContentNode, ContentTitle, EntityFilters, EntityGrid, EntityValue, useLang, useView } from '@shapeable/ui';
import { TrendView } from '../../types';
import { IconHeading } from '../elements/icon-heading';
import { HorizonCardGrid } from './horizon-card-grid';
import { EntityOutlineNumber } from './entity-outline-number';
import { GptLayout } from '@shapeable/gpt';
import { HorizonIcon } from '@shapeable/icons';
import { horizonIntro } from '../../data';
import { compact, sortBy } from 'lodash';
const cls = classNames('trend-view-layout');

// -------- Types -------->

// -------- Props -------->

export type TrendViewLayoutProps = Classable & HasChildren & { 
  entity?: Trend;
};

export const TrendViewLayoutDefaultProps: Omit<TrendViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
});

const IntroStyles = breakpoints({
  base: css`
    
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const HorizonTypeStyles = breakpoints({
  base: css`
    
  `,
});

const FocusAreasListStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const FocusTitleStyles = breakpoints({
  base: css`
    font-size: 1em;
  `,
});

const FocusAreasItemsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.UNIT(5)};
    
  `,
});

const FocusAreaStyles = breakpoints({
  base: css`
    flex-basis: 45%;
    font-size: 0.8em;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const LibraryContainerStyles = breakpoints({
  base: css`
    border-radius: ${theme.UNIT(2)};
  `,
  desktop: css`
    padding: ${theme.UNIT(3)};
  `
});

const FiltersStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
  `,
});

const CitationsStyles = breakpoints({
  base: css`

  `,
  
});

const GptLayoutStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
    Section: styled.section`${SectionStyles}`,

      IconHeading: styled(IconHeading)``,
      Horizontype: styled(HorizonCardGrid)`${HorizonTypeStyles}`,
      
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,

      FocusAreasList: styled.div`${FocusAreasListStyles}`,
        FocusAreasTitle: styled(CellLabel)`${FocusTitleStyles}`,
        FocusAreasItems: styled.div`${FocusAreasItemsStyles}`,
          FocusArea: styled(EntityOutlineNumber)`${FocusAreaStyles}`,

      LibraryContainer: styled.div`${LibraryContainerStyles}`,
      Filters: styled(EntityFilters)`${FiltersStyles}`, 
      Reports: styled(EntityGrid)`${CitationsStyles}`,

      CitationCard: styled(CitationCard)``,
      
      GptLayout: styled(GptLayout)`${GptLayoutStyles}`,
};

export const TrendViewLayout: Shapeable.FC<TrendViewLayoutProps> = (props) => {
  const { className, children, entity } = props;
  const view = `trend-${entity.slug}`;
  const { resultsFor } = useView(view);

  const { description, horizons = [], topics = [], citations = [], feedEntries = [] } = entity;

  const trendReports = [ ...citations, ...feedEntries ];

  const t = useLang();

  const hasHorizons = !!horizons.length;
  const hasTopics = !!topics.length;
  const hasCitations = !!citations.length;
  const hasFeedEntries = !!feedEntries.length;
  const hasReports = !!trendReports.length;

  const { showView } = useView<TrendView>(view, 'overview');

  const reports = sortBy([
    ...resultsFor({type: 'Citation', data: citations, by: 'name'}),
    ...resultsFor({type: 'FeedEntry', data: feedEntries, by: 'name'}),
  ], 'name');
  
  return (
    <My.Container className={cls.name(className)}>
      {
        showView('ai') && 
        <My.GptLayout entity={entity} />
      }
      {
        showView('overview') && 
        <My.Body>
          <My.Section>
            <My.Intro entity={entity} />
            <My.Description entity={description} />
          </My.Section>
        
          {
            hasHorizons && 
            <My.Section>
            <My.IconHeading icon={<HorizonIcon colors={{ fill: 'strong' }} />}>{t('Horizons')}</My.IconHeading>
              <My.Description entity={horizonIntro}/>
              <My.Horizontype items={horizons} />
            </My.Section>
          }

        <My.Section>
          {
            hasTopics && 
              <My.FocusAreasList>
                <My.FocusAreasTitle>{t("Focus Areas:")}</My.FocusAreasTitle>
                <My.FocusAreasItems>
                {
                  topics.map(topic => <My.FocusArea key={topic.id} entity={topic} />)
                }
                </My.FocusAreasItems>
              </My.FocusAreasList>
          }
        </My.Section>
      </My.Body>
    }
    {
      showView('resources') && 
      <>
        {
          entity?.intro?.text &&
          <My.Section>
            <My.Intro entity={entity} />
          </My.Section>
        }
        <My.Filters 
          view={view}
          typeToggles={compact([
            hasCitations &&
            {
              name: 'Citation',
              label: 'External Reports',
            },
            hasFeedEntries &&
            {
              name: 'FeedEntry',
              label: 'Articles',
            },
          ])}
        />
        {
          hasReports && <My.Reports items={reports} spacing={4} maxColumns={3}/>
        }
      </>
    }
    </My.Container>
  )
};

TrendViewLayout.defaultProps = TrendViewLayoutDefaultProps;
TrendViewLayout.cls = cls;