import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityDataGrid, PageLayoutStandard, SiteHeaderProvider } from '@shapeable/ui';
import { EXPERTISE_DATA, TREND_EXPERTISE_DEFN } from '../../mock';

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`

    /* override title styles via BEM here to affect spacing */ 
    .shp--page-layout-standard__title {
      max-width: 700px;
      font-size: 2em;

    }
  `,
  tablet: css`
    .shp--page-layout-standard__title {
      line-height: 1.1em;
      font-size: 3em;
    }
  `,
  desktop: css`
    .shp--page-layout-standard__title {
      line-height: 1.1em;
      font-size: 4em;
    }
  `
});

const NavigatorStyles = breakpoints({
  base: css`
    
  `,
});

const GridStyles = breakpoints({
  base: css`
    margin: 40px;
  `,
});



// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,

  Grid: styled(EntityDataGrid)`${GridStyles}`,
};

export const PageLayoutHome: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;


  return (
    <>
    { /* @ts-ignore */ }
    {/* <My.Grid items={EXPERTISE_DATA} defn={TREND_EXPERTISE_DEFN} /> */}
    <My.Layout 
      entity={entity}
      slices={slices}
      className={className}
      headerProps={{ variant: 'overlay' }}
    />
    </>
  )
};
