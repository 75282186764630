const gql = String.raw;
  
export const Instrument = gql`
query AllInstrumentsQuery {
  allInstruments {
    
      
    
        id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
    
      
      
            openGraph { 
              id title 
              
              image { 
                id url url2x 
                thumbnails { 
                  id bubble { id url url2x }
      card { id url url2x }
                }
                
              } 
            }
          
    
    types { id name slug path __typename _schema { label pluralLabel } }
    topics { id name slug path __typename _schema { label pluralLabel } }
  }
  
}

`;
