import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ExplorerTabs, SiteFooterLayout, useView } from '@shapeable/ui';
import { ExplorerHomeLayout } from './explorer-home-layout';
import { ExplorerGptLayout } from './explorer-gpt-layout';
import { ExplorerCountriesLayout } from './explorer-countries-layout';
import { ExplorerNewsLayout } from './explorer-news-layout';
import { ExplorerMarketMapLayout } from './explorer-market-map-layout';
import { ExplorerCommunityLayout } from './explorer-community-layout';

const cls = classNames('explorer-layout');

// -------- Types -------->

export type ExplorerIndexLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerIndexLayoutDefaultProps: Omit<ExplorerIndexLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
});

const ViewStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background: ${theme.COLOR('app-chrome')};
  `,
});

const TabsStyles = breakpoints({
  base: css`
  `,
});

const GptStyles = breakpoints({
  base: css`
    
  `,
});

const FooterLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const HomeLayoutStyles = breakpoints({
  base: css`
  `,
});

const CommunityLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const CountriesLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const MarketMapLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const NewsLayoutStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Tabs: styled(ExplorerTabs)`${TabsStyles}`,
      View: styled.div`${ViewStyles}`,
        GptLayout: styled(ExplorerGptLayout)`${GptStyles}`,
        HomeLayout: styled(ExplorerHomeLayout)`${HomeLayoutStyles}`,
        CommunityLayout: styled(ExplorerCommunityLayout)`${CommunityLayoutStyles}`,
        CountriesLayout: styled(ExplorerCountriesLayout)`${CountriesLayoutStyles}`,
        MarketMapLayout: styled(ExplorerMarketMapLayout)`${MarketMapLayoutStyles}`,
        NewsLayout: styled(ExplorerNewsLayout)`${NewsLayoutStyles}`,

    Footer: styled(SiteFooterLayout)`${FooterLayoutStyles}`,
};

export const ExplorerIndexLayout: Shapeable.FC<ExplorerIndexLayoutProps> = (props) => {
  const { className, entity } = props;

  const { isActiveView } = useView('explorer', 'home' as string);
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Tabs useStickyStyle />
      <My.View>
      {
        isActiveView('ai') && 
        <My.GptLayout entity={entity} />
      }
      {
        isActiveView('home') && 
        <My.HomeLayout entity={entity} />
      }
      {/* {
        isActiveView('community') &&
        <My.CommunityLayout />
      } */}
      {
        isActiveView('countries') &&
        <My.CountriesLayout />
      }
      {
        isActiveView('market-map') &&
        <My.MarketMapLayout />
      }
      {
        isActiveView('news') &&
        <My.NewsLayout />
      }

      </My.View>
    </My.Container>
  );
};

ExplorerIndexLayout.defaultProps = ExplorerIndexLayoutDefaultProps;
ExplorerIndexLayout.cls = cls;