import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, HorizonTypesProvider as Provider } from '@shapeable/ui';

const QUERY = graphql`
query PlatformAllHorizonTypesQuery {
  platform { allHorizonTypes {
    id name slug typeLabel badge path hashPath updated __typename _schema { label pluralLabel }
    years subtitle
  } }
}

`
export const HorizonTypesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allHorizonTypes' ) }>{children}</Provider>;
}