import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { OrganisationTypesProvider as Provider, platformProviderValue } from '@shapeable/ui';

const ORGANISATION_TYPES_QUERY = graphql`
query PlatformAllOrganisationTypesQuery {
  
    platform { allOrganisationTypes {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      
    } }
  
}

`;

export const OrganisationTypesProvider: React.FC<any> = ({ children }) => {
  /* @ts-ignore */
  return <Provider value={ platformProviderValue( useStaticQuery(ORGANISATION_TYPES_QUERY), 'allOrganisationTypes' ) }>{children}</Provider>;
};