const gql = String.raw;
  
export const OrganisationType = gql`
query AllOrganisationTypesQuery {
  
    allOrganisationTypes {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      
    }
  
}

`;
