import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, HasStyle, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, pointAtOrigin } from '@shapeable/utils';
import { normalizeAngle } from '../../utils';
import { animated, to } from '@react-spring/web';
import { md5 } from 'js-md5';
const cls = classNames('outline-node');

// -------- Types -------->

export type OutlineNodeProps = HasStyle & Classable & HasChildren & {
  onClick?: () => void;
  outlineNumber?: string;
  chromeColor?: string; // the color of the dot background, line, and label
  id: string;


  activeChromeColor?: string;
  activeColor?: string;
  hoverChromeColor?: string;
  hoverColor?: string;

  label?: string;
  labelAngle?: number; // angle of the label in degrees from 12 o'clock  
  color?: string;
  size?: number;
  fontSize?: number;
  cx?: number;
  cy?: number;
  showBounds?: boolean;
  isActive?: boolean;
  lineDotSize?: number;
  lineLength?: number;
  dotShadow?: string;

  labelColor?: string;
  activeLabelColor?: string;

}

export const OutlineNodeDefaultProps: Omit<OutlineNodeProps, 'id'> = {
  lineLength: 40,
  labelAngle: 0,
  lineDotSize: 2,
  size: 42,
  cx: 0,
  cy: 0,
  showBounds: false,
  chromeColor: '#FFFFFF',
};

// -------- Child Component Props -------->

type ContainerProps = {

};

type LineProps = {
  _color: string;
};

type LineDotProps = {
  _color: string;
};

type LabelProps = {
  _color: string;
};

type OutlineNumberProps = {
  _color: string;
  size: number;
};

type DotProps = {
  isActive?: boolean;
  _hoverBorderColor: string;
  _borderColor: string;

  _backgroundColor: string;
  _hoverBackgroundColor: string;

  _activeBackgroundColor: string;
  _activeBorderColor: string;

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(14)};
  `,
});

const LabelStyles = breakpoints({
  base: css`
    font-size: 1em;
    font-weight: 500;
    ${({ _color }: LabelProps ) => css`
      fill: ${_color};
    `}
  `,
});

const DotStyles = breakpoints({
  base: css`
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    circle {
      stroke-width: 2px;
      ${({ _backgroundColor, _borderColor, _activeBorderColor, _activeBackgroundColor, isActive }: DotProps ) => css`
        stroke: ${isActive ? _activeBorderColor : _borderColor};
        fill: ${isActive ? _activeBackgroundColor : _backgroundColor};
      `}
    }

    ${({ _borderColor }: DotProps ) => css`
      text, tspan {
        fill: ${_borderColor};
      }
    `}

    ${({ _borderColor, _activeBorderColor, _activeBackgroundColor, isActive }: DotProps ) => isActive && css`
      text, tspan {
        fill: ${_activeBorderColor};
      }
    `}

    ${({ _backgroundColor, _borderColor, _hoverBorderColor, _activeBorderColor, _activeBackgroundColor, _hoverBackgroundColor }: DotProps ) => css`
      &:hover  {
        circle {
          fill: ${_hoverBackgroundColor};
          stroke: ${_hoverBorderColor};
        }

        text, tspan {
          fill: ${_hoverBorderColor};
        }
      }
    `}
  `,
});

const DotCircleStyles = breakpoints({
  base: css`
  `,
});



const OutlineNumberStyles = breakpoints({
  base: css`
    font-weight: 600;

    ${({ _color, size }: OutlineNumberProps ) => css`
      font-size: ${size}px;
      cursor: pointer;
    `}
  `,
});

const LineStyles = breakpoints({
  base: css`
    stroke-width: 1px;
    ${({ _color }: LineDotProps ) => css`
      stroke: ${_color};
    `}
  `,
});

const LineDotStyles = breakpoints({
  base: css`
    ${({ _color }: LineDotProps ) => css`
      fill: ${_color};
    `}
  `,
});


// -------- Components -------->

const My = {
  Container: styled(animated.svg)<ContainerProps>`${ContainerStyles}`,
    Label: styled.text<LabelProps>`${LabelStyles}`,

    Dot: styled.g<DotProps>`${DotStyles}`,
      DotCircle: styled.circle`${DotCircleStyles}`,
        OutlineNumber: styled(animated.text)<OutlineNumberProps>`${OutlineNumberStyles}`,

    Line: styled.line<LineProps>`${LineStyles}`,
      LineDot: styled.circle<LineDotProps>`${LineDotStyles}`,
};

export const OutlineNode: Shapeable.FC<OutlineNodeProps> = (props) => {
  const { 
    className, label, color, size, isActive, labelAngle, fontSize, 
    cx, cy, style, children, lineLength, lineDotSize, id,
    chromeColor, outlineNumber, showBounds, onClick,
    activeChromeColor, activeColor, hoverChromeColor, hoverColor,
  } = props;
  
  const labelColor = props.labelColor || chromeColor;
  const activeLabelColor = props.activeLabelColor || chromeColor;
  
  const dotRadius = (size / 2);

  const totalRadius = dotRadius + lineLength + (lineDotSize / 2);

  const x = cx - totalRadius;
  const y = cy - totalRadius;

  const width = totalRadius * 2;
  const height = totalRadius * 2;

  const labelAngleFrom12 = labelAngle;
  const originX = totalRadius;
  const originY = totalRadius;

  const pointAt = pointAtOrigin(originX, originY);

  const normalizedLabelAngle = normalizeAngle(labelAngle);
  const isTopHalf = normalizedLabelAngle >= 180;

  const [ lineX1, lineY1 ] = pointAt(dotRadius, labelAngleFrom12);
  const [ lineX2, lineY2 ] = pointAt(dotRadius + lineLength, labelAngleFrom12);
  const [ lineDotX, lineDotY ] = pointAt(dotRadius + lineLength + (lineDotSize / 2), labelAngleFrom12);
  const [ lineLabelX, lineLabelY ] = pointAt(dotRadius + lineLength + (lineDotSize / 2) + 30, labelAngleFrom12);
  const [ outlineNumberX, outlineNumberY ] = [ originX, originY + 6 ];
  
  const lines = label.split(' ');

  // this "magic sauce" does a reasonable job of aligning labels correctly around the circle!
  let labelXDelta = -20;
  let labelYDelta = 0;
  let textAnchor = 'left';

  if (normalizedLabelAngle > 90 && normalizedLabelAngle < 270) {
    labelXDelta = 18;
    textAnchor = 'end';
  }

  if (normalizedLabelAngle > 180 && normalizedLabelAngle < 270) {
    labelYDelta = -10;
  }

  const uniqueId = `shadow-${md5(id)}-${Math.random().toString(36).slice(2, 11)}`;

  return (
    <My.Container style={style} overflow="visible" x={x} y={y} className={cls.name(className)} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <filter id={`shadow-${uniqueId}`} y="-20%" x="-20%" height="140%" width="140%">
          <feDropShadow dx="3" dy="3" stdDeviation="3" floodOpacity="0.2" />
        </filter>
      </defs>
      {
        showBounds && 
        <rect x={0} width={width} y={0} height={height} stroke="#CCCCCC" fill="none"></rect>
      }
      <g>
        <My.Line x1={lineX1} y1={lineY1} x2={lineX2} y2={lineY2} _color={chromeColor} />
        <My.LineDot _color={chromeColor} cx={lineDotX} cy={lineDotY} r={lineDotSize} />
        <My.Label 
          textAnchor={textAnchor}
          x={lineLabelX + labelXDelta}
          y={lineLabelY + labelYDelta + (isTopHalf && lines.length === 1 ? 12.5 : 0)} 
          _color={isActive ? activeLabelColor : labelColor}>
          {
            lines.map((word, i) => 
              <tspan key={`${id}-word-${i}`} dy={`${i * 1.4}em`} x={lineLabelX + labelXDelta}>{word}</tspan>
            )
          }
        </My.Label>
      </g>

      
      <My.Dot 
        isActive={isActive} 
        _hoverBorderColor={hoverColor || chromeColor} 
        _activeBackgroundColor={activeChromeColor || hoverChromeColor}
        _hoverBackgroundColor={hoverChromeColor || activeChromeColor || color}
        _activeBorderColor={activeColor || hoverColor || chromeColor}  
        _borderColor={color} 
        _backgroundColor={chromeColor} 
        onClick={onClick}>
        <My.DotCircle data-cool="yes" filter={`url(#shadow-${uniqueId})`} r={dotRadius} cx={originX} cy={originY} />
        <My.OutlineNumber size={fontSize} textAnchor="middle" style={{ width: `${size}px` }} x={outlineNumberX} y=
        {outlineNumberY} _color={color}>{children || outlineNumber}</My.OutlineNumber>
      </My.Dot>
    </My.Container>
  )
};

OutlineNode.cls = cls;
OutlineNode.defaultProps = OutlineNodeDefaultProps;