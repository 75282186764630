import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme, ColorTokens } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { entityAsideMenuKey, EntityConversationLayout, EntityValue, LoadingLayout, UiComponentName, useActivePerson, useComponentProps, useTrends, useView } from '@shapeable/ui';
import { useDimensions } from '@shapeable/ui';
import { TrendNewsListing } from '../entities/trend-news-listing';

const cls = classNames('explorer-news--main-layout');

// -------- Types -------->

// -------- Props -------->

export type ExplorerNewsMainLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerNewsMainLayoutDefaultProps: Omit<ExplorerNewsMainLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    height: 100%;
  `,
});

// 
const BannerContainerStyles = breakpoints({
  base: css`
    border-bottom: 1px solid ${theme.COLOR('line-subtle')};
  `,
});

const BannerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(1)} ${theme.UNIT(1)} ${theme.UNIT(2)};
  `,
  tablet: css`
    padding: ${theme.UNIT(1)} ${theme.UNIT(1)} ${theme.UNIT(3)};
  `,
});

const NewsListingStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  NAME: 'ExplorerNewsMainLayout' as UiComponentName,

  Container: styled.div`${ContainerStyles}`,
  LoadingLayout: LoadingLayout,

  BannerContainer: styled.div`${BannerContainerStyles}`,
  Banner: styled(EntityValue)`${BannerStyles}`,

  NewsListing: styled(TrendNewsListing)`${NewsListingStyles}`,
};

export const ExplorerNewsMainLayout: Shapeable.FC<ExplorerNewsMainLayoutProps> = (props) => {
  const { className, children, entity } = useComponentProps(My.NAME, ExplorerNewsMainLayoutDefaultProps, props) as ExplorerNewsMainLayoutProps;
  
  const items = useTrends();
  const initialSubView = entityAsideMenuKey(items?.[0]);
  const { showSubView, activeSubView } = useView('navigator', 'news', initialSubView);
  const activeItem = items.find((item) => entityAsideMenuKey(item) === activeSubView);
  
  return (
    <My.Container className={cls.name(className)}>
      { 
        activeItem && showSubView(entityAsideMenuKey(activeItem)) && (
          <>
            <My.NewsListing entity={activeItem} />
          </>
        )
      }
      <My.Container>
    </My.Container>
    {children}
    </My.Container>
  )
};

ExplorerNewsMainLayout.cls = cls;