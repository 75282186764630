import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { TopicsProvider as Provider, platformProviderValue } from '@shapeable/ui';

const TOPICS_QUERY = graphql`
query PlatformAllTopicsQuery {
  
    platform { allTopics {
      
        
      
          
      
        
      
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
      
          
      
          
      
                openGraph { 
      
                  id title 
      
                  
      
                  image { 
      
                    id url url2x 
      
                    thumbnails { 
      
                      id bubble { id url url2x }
      
          card { id url url2x }
      
                    }
      
                    
      
                  } 
      
                }
      
              
      
        
        color {    id value veryLightValue lightValue darkValue veryDarkValue  }
        dataSetId
        outlineNumber
        trend { id slug }
        # horizons {
        #   id slug name path
        #   type {
        #     id slug name
        #   }
        # }
      
    } }
  
}

`;

export const TopicsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(TOPICS_QUERY), 'allTopics' ) }>{children}</Provider>;
};
