const gql = String.raw;
  
export const Trend = gql`
query TrendBySlugQuery($slug: ID!) {
  trendBySlug(slug: $slug) {
    
      
    
        id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
    
      
      
            openGraph { 
              id title 
              description { plain text }
              image { 
                id url url2x 
                thumbnails { 
                  id bubbleLarge { id url url2x }
      mainBanner { id url url2x }
      halfBanner { id url url2x }
                }
                
              } 
            }
          
      
        entityType { useAi }
        entityViews {
          id name hash label slug count showCount
          type { id name slug }
          slices {
            id name slug label updated
            title 
            titleRich { id text }
            layout { id name slug component }
            themeMode { id name slug }
            openGraph { 
              image { url thumbnails { large { url } bubble { url url2x } } }
            }
            emptyTout { id text }
            ownEmptyTout { id text }
            entityFormFields {
              name
              label
              description
              ownDescription
              type
              formFieldType
              entityTypeName
              entityTypeNamePlural
            }
            headerFontType { internalName }
            bodyFontType { internalName }
            disabled
            connectedEntities { name label internalName }
            entityLayout { internalName }
            created updated
            outro { id text }
            intro { id text }
            content { id plain text }
            email
            phone
            twitter
            threads
            linkedin
            backgroundColor { id value }
            color { id value }
            teams { id name slug __typename _schema { label pluralLabel } }
            objectives { id name slug __typename type { id name slug __typename } }
            icon { id slug name component }
            people {
              id name slug path __typename
              organisation { id name }
              linkedin
              position
              openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
            }
            indicators { 
              id name slug title
            }
            indicatorGroups { 
              id slug name 
            }
            economies {
              id name slug path __typename _schema { label pluralLabel }
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            } 
            locations { 
              id name slug __typename _schema { label pluralLabel } 
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            }
            posts { 
              id name slug path __typename _schema { label  pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            }
            feedEntries {
              name id slug path url __typename _schema { label pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { title image { url thumbnails { full { url } } } }
            }
            pages {
              id name slug path __typename _schema { label pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            }
            organisations { 
              id name slug path __typename _schema { label pluralLabel }
              openGraph { image { url url2x thumbnails { bubble { url url2x } card { url url2x } } } }
            }
            topicExpertise {
              __typename typeLabel badge name slug
              levelOfExpertise { name slug }
              topics { __typename outlineNumber id name slug path 
                openGraph {
                  title description { plain }
                  image { url url2x thumbnails { medium { url url2x } } }
                }
              }
            }
            trendExpertise {
              __typename typeLabel badge name slug
              levelOfExpertise { name slug }
              trends { __typename outlineNumber id path name slug
                openGraph { title description { plain } image { url url2x thumbnails { medium { url url2x } } }
      }
              }
            }
          }
        }
      
      outlineNumber
      color { __typename id slug name value }
      description { id text }
      intro { id text }
      gptName
      embeds {
        citations {
          slug url name authors { id name slug } 
          authorShowsEtAl edition publication accessDate 
          startPage volume footnoteNumber year
        }
        posts {
          id name slug path
          openGraph {
            title description { plain(truncate: {words: 20}) }
            image { url url2x type }
          }
        }
        imageAssets {
          id
          slug
          image {
            id url url2x width height type 
          }
        }
      }
      banner {
        id
        thumbnail { url } 
        image { 
          thumbnails {
            mainBanner { id url }
          }
          url 
        }
      }
      feedEntries {
        
          
        
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
        
          
          
                openGraph { 
                  id title 
                  
                  image { 
                    id url url2x 
                    thumbnails { 
                      id bubble { id url url2x }
          card { id url url2x }
                    }
                    
                  } 
                }
              
        
        published typeLabel
        economies {          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }                openGraph {            id title                       image {              id url url2x              thumbnails {                id bubbleMedium { id url url2x }             }                        }          }         }
        types { id name slug }
      }
      posts {
        id slug name __typename path
        openGraph { 
          title description { plain }
          image { url thumbnails { large { url } } }
        }
      }
      citations {
        __typename _schema { label pluralLabel }
        id path slug url name
        authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
      }
      topics {
        id name outlineNumber slug path __typename 
        intro { id text(truncate: {ellipsis: "...", words: 45}) }
        color { id name value } 
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      pages {
        __typename id name path slug title subtitle pretitle 
        openGraph { description { id plain } image { url } }
        intro { id text }
        orderNumber
        type { id name slug }
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
    
    horizons {
    name path slug _schema { label pluralLabel }
    type { id name years }
    description { text }
    }
  }
}
`;
