const gql = String.raw;
  
export const HorizonType = gql`
query AllHorizonTypesQuery {
  allHorizonTypes {
    id name slug typeLabel badge path hashPath updated __typename _schema { label pluralLabel }
    years subtitle
  }
}

`;
