const gql = String.raw;
  
export const Economy = gql`
query AllEconomiesQuery {
  
    allEconomies {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      globalRegions { 
         
           
         
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel } 
         
           
           
                openGraph {  
                  id title  
                   
                  image {  
                    id url url2x  
                    thumbnails {  
                      id bubbleMedium { id url url2x } 
                    } 
                     
                  }  
                } 
               
        
        abbreviation
      }
    }
  
}
`;
