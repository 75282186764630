import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, ContentNode } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SiteFooterLayout, ComponentPropsProvider, EntityFilters, useInfiniteScrollArray, useOrganisations, useSearch, useView, usePeople, ExplorerEntityGridLayout, useEntityTypeDynamic, useEntityAll, GridLoader, ExplorerTabs } from '@shapeable/ui';
import { classNames, linkedMatchesFilter, entityNormalizedField, entityGridNameSort } from '@shapeable/utils';
import { compact, sortBy } from 'lodash';
import { useCommunityFilters } from '../../hooks/use-community-filters';
import { Organisation, Person } from '@shapeable/adsw-types';
const cls = classNames('explorer-market-map-layout');

// -------- Types -------->

export type ExplorerMarketMapLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerMarketMapLayoutDefaultProps: Omit<ExplorerMarketMapLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const FiltersStyles = breakpoints({
  base: css`
  `,
});



// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerMarketMapLayout: Shapeable.FC<ExplorerMarketMapLayoutProps> = (props) => {
  const { className, children } = props;

  const view = 'navigator';
  const { resultsFor, filters } = useView(view);
  const { types = [], countries = [], trends = [], topics = [] } = filters;

  
  const organisationsQuery = useEntityAll('Organisation');
  const peopleQuery = useEntityAll('Person');
  
  const allOrganisations = entityGridNameSort(organisationsQuery.items);
  const allPeople = entityGridNameSort(sortBy(peopleQuery.items));

  const { selects, typeToggles } = useCommunityFilters();

  const organisations = allOrganisations.filter((organisation: Organisation) => (
    linkedMatchesFilter(organisation.countries, countries) &&
    linkedMatchesFilter(compact([organisation?.type]), types) && 
    linkedMatchesFilter(organisation.trends, trends) &&
    linkedMatchesFilter(organisation.topics, topics)
  ));

  const people = allPeople.filter((person: Person) => (
    linkedMatchesFilter([person.country], countries) &&
    linkedMatchesFilter(person?.trends, trends) &&
    linkedMatchesFilter(person.topics, topics)
  ));

  const stakeholders = [
    ...(resultsFor({ type: 'Organisation', data: organisations })),
    ...(resultsFor({ type: 'Person', data: people }))
  ];

  const loading = organisationsQuery.loading || peopleQuery.loading;

   const description: ContentNode = {
      text: 'This listing of key organisations and innovators across the world is provided as an information source for further research. Unless badged as Partners or Members, they currently have no affiliation.',
    };


  return (
    <ComponentPropsProvider value={{ 
      EntityBubbleImage: {
        showBorder: true,
      },
     }}>

      <My.Container 
        loading={loading}
        className={cls.name(className)}
        view={view}
        description={description}
        showSearchBar
        filters={ 
          <My.Filters
            view={view}
            results={[...organisations, ...people]}
            selects={selects}
            typeToggles={typeToggles}
          /> 
        }
        items={stakeholders}
      />
    </ComponentPropsProvider>
  )
};

ExplorerMarketMapLayout.defaultProps = ExplorerMarketMapLayoutDefaultProps;
ExplorerMarketMapLayout.cls = cls;