import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Banner } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ComponentPropsProvider, DataVizMobileMain, ExplorerEntityDetailsLayout, ExplorerProviders, SiteFooterProvider } from '@shapeable/ui';
import { NavigatorMap } from '../elements/navigator-map';
import { useNavigatorMap } from '../../hooks/use-navigator-map';
import { ExplorerHomeViewLayout } from './explorer-home-view-layout';
const cls = classNames('radar-home-layout');
import NET_ZERO_NAVIGATOR_HOME_BANNER from '../../images/net-zero-navigator-home-banner.png';
import { ExplorerHomeBannerLayout } from './explorer-home-banner-layout';
import { DonutNavigatorIconGlyph, IconSheet } from '@shapeable/icons';

// -------- Types -------->

export type ExplorerHomeLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerHomeLayoutDefaultProps: Omit<ExplorerHomeLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->


const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: ${theme.COLOR('canvas')};
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
  desktop: css`
    
  `,
});

const MapStyles = breakpoints({
  base: css`
    margin-top: -20px;
  `,
});

const DataVizContainerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5, 2)} ${theme.UNIT(6)} ${theme.UNIT(6)} ${theme.UNIT(6)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
    Map: styled(NavigatorMap)`${MapStyles}`,
    DataVizContainer: styled.div`${DataVizContainerStyles}`,
};

export const ExplorerHomeLayout: Shapeable.FC<ExplorerHomeLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const banner: Banner = {
    ...(entity.banner || {}),
    image: {
      ...(entity.banner?.image || {}),
      url: NET_ZERO_NAVIGATOR_HOME_BANNER, 
    }
  };

  // argh, entity banner needs a cleanup

  const patchedEntity = {
    ...entity,
    banner,
    banners: [banner],
  };

  const { isShown } = useNavigatorMap();
  
  return (
    <ExplorerProviders>
    <SiteFooterProvider value={{
      backgroundColor: 'bright',
    }}>
    <ComponentPropsProvider value={{ 
      EntityBanner: {
        showOverlay: false,
        customAspectRatio: { base: 2000 / 672 },
      }
     }}>
    <My.Container className={cls.name(className)}>
      <My.Layout
        asideMaxWidth={700}
        entity={patchedEntity}
        isAsideSticky={true}
        stickyTopOffset={110}
        asideIsRevealed={isShown}

        banner={
          <ExplorerHomeBannerLayout entity={entity} />
        }

        content={
          <ExplorerHomeViewLayout entity={entity} />
        }
        dataVizMain={
          <DataVizMobileMain isExpanded label="Synapse Navigator Map" icon={DonutNavigatorIconGlyph} >
            <NavigatorMap />
          </DataVizMobileMain>
        }

        dataViz={
          <My.DataVizContainer>
            <NavigatorMap />
          </My.DataVizContainer>
        }
      />
      {children}
    </My.Container>
    </ComponentPropsProvider>
    </SiteFooterProvider>
    </ExplorerProviders>
  )
};

ExplorerHomeLayout.defaultProps = ExplorerHomeLayoutDefaultProps;
ExplorerHomeLayout.cls = cls;