import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, FeedEntry, Post } from '@shapeable/types';
import { breakpoints, theme, ColorTokens } from '@shapeable/theme';
import { classNames, linkedMatchesFilter } from '@shapeable/utils';
import { CellLabel, EntityCard, EntityFilters, EntityGrid, Grid, UiComponentName, useComponentProps, useEconomies, useFeedEntries, useLang, usePosts, useView } from '@shapeable/ui';
import { sortBy, compact, reverse } from 'lodash';
import { Economy, Trend } from '@shapeable/adsw-types';

const cls = classNames('trend-news-listing');

// -------- Types -------->

// -------- Props -------->

export type TrendNewsListingProps = Classable & HasChildren & { 
  entity?: Trend;
};

export const TrendNewsListingDefaultProps: Omit<TrendNewsListingProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(4)};
  `,
});

const LabelStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(2)};
    color: ${theme.COLOR('text-mid')};
  `,
});

const ListingViewStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const CardStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  NAME: 'TrendNewsListing' as UiComponentName,

  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
    Label: styled(CellLabel)`${LabelStyles}`,
    ListingView: styled(EntityGrid)`${ListingViewStyles}`,
    Card: styled(EntityCard)`${CardStyles}`,
};

export const TrendNewsListing: Shapeable.FC<TrendNewsListingProps> = (props) => {
  const { className, children, entity } = useComponentProps(My.NAME, props, TrendNewsListingDefaultProps) as TrendNewsListingProps;

  const { feedEntries = [], posts = [] } = entity;

  const t = useLang();
  const countries = useEconomies();
  
  const view = 'navigator';
  const { resultsFor, filters } = useView(view);
  const { countries: selecteCountries = [] } = filters;

  const hasEconomies = feedEntries.some((feed) => feed?.economies.length > 0);
    
  const filteredPosts = posts.filter((post) => (
    linkedMatchesFilter(post.economies, selecteCountries)
  ));

  const filteredArticles = feedEntries.filter((article) => (
    linkedMatchesFilter(article?.economies, selecteCountries)
  ));

  const toggledItems = [
    ...(resultsFor({ type: 'FeedEntries', data: filteredArticles, by: 'name' })),
    ...(resultsFor({ type: 'Posts', data: filteredPosts, by: 'name' }))
  ];

  const listItems = reverse(sortBy(toggledItems, 'published'));

  return (
    <My.Container className={cls.name(className)}>
        {
          hasEconomies && (
          <My.Filters 
            view={view}
            results={[...feedEntries, ...posts]}
            selects={compact([
              {
                name: 'countries',
                fieldName: 'countries',
                items: countries,
                pluralLabel: 'Countries',
                getResultFieldSlugs: (result: FeedEntry | Post) => result?.economies.map((economy) => economy.slug),
              },
              ])}
            typeToggles={[
              // {
              //   name: 'FeedEntries',
              //   label: 'Articles',
              // },
              // {
              //   name: 'Posts',
              //   label: 'Posts',
              // },
            ]}
            />
          )
        }
        <My.ListingView 
          tabletAutoColumns={3}
          spacing={4} 
          items={listItems}
        />
    {children}
    </My.Container>
  )
};

TrendNewsListing.cls = cls;