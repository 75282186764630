const gql = String.raw;
  
export const Organisation = gql`
query AllOrganisationsQuery {
  
    allOrganisations {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      type { slug name }
      listPriority
      countries {          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }                openGraph {            id title                       image {              id url url2x              thumbnails {                id bubbleMedium { id url url2x }             }                        }          }         }
      topics {          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }                openGraph {            id title                       image {              id url url2x              thumbnails {                id bubbleMedium { id url url2x }             }                        }          }         }
      trends {          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }                openGraph {            id title                       image {              id url url2x              thumbnails {                id bubbleMedium { id url url2x }             }                        }          }         }
      location { 
         
           
         
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel } 
         
           
           
                openGraph {  
                  id title  
                   
                  image {  
                    id url url2x  
                    thumbnails {  
                      id bubbleMedium { id url url2x } 
                    } 
                     
                  }  
                } 
               
         
        latitude longitude
      }
    }
  
  allOrganisations {
    country { 
       
         
       
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel } 
       
         
         
              openGraph {  
                id title  
                 
                image {  
                  id url url2x  
                  thumbnails {  
                    id bubbleMedium { id url url2x } 
                  } 
                   
                }  
              } 
             
      
    }
    trends {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubbleMedium { id url url2x }
                  }
                  
                } 
              }
            
      
    }
  }
}

`;
