import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { PagesProvider as Provider, platformProviderValue } from '@shapeable/ui';
import { sortBy } from 'lodash';

const PAGES_QUERY = graphql`
query PlatformAllPagesQuery {
  
    platform { allPages {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      parent { id path slug name }
      types { id name }
      intro { id text }
      slices {
        id name slug path
        layout { id name slug component }
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        images {
          id name slug
          image { 
            id url url2x type width height 
          }
        }
      }
    } }
  
}

`;

export const PagesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(PAGES_QUERY), 'allPages' ), 'name') }>{children}</Provider>;
}