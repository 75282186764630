import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { FeedEntriesProvider as Provider, platformProviderValue } from '@shapeable/ui'
import { sortBy } from 'lodash';

const FEED_ENTRIES_QUERY = graphql`
query PlatformAllFeedEntriesQuery {
  
    platform { allFeedEntries {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      published author
      types {          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }                openGraph {            id title                       image {              id url url2x              thumbnails {                id bubbleMedium { id url url2x }             }                        }          }         }
      economies {          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }                openGraph {            id title                       image {              id url url2x              thumbnails {                id bubbleMedium { id url url2x }             }                        }          }         }
      organisations {          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }                openGraph {            id title                       image {              id url url2x              thumbnails {                id bubbleMedium { id url url2x }             }                        }          }         }
      topics {          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }                openGraph {            id title                       image {              id url url2x              thumbnails {                id bubbleMedium { id url url2x }             }                        }          }         }
      trends {          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }                openGraph {            id title                       image {              id url url2x              thumbnails {                id bubbleMedium { id url url2x }             }                        }          }         }
    } }
  
}

`;

export const FeedEntriesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(FEED_ENTRIES_QUERY), 'allFeedEntries' ), 'name') }>{children}</Provider>;
}