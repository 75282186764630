import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, EconomiesProvider as Provider } from '@shapeable/ui';

const ECONOMIES_QUERY = graphql`
query PlatformAllEconomiesQuery {
  
    platform { allEconomies {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      globalRegions { 
         
           
         
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel } 
         
           
           
                openGraph {  
                  id title  
                   
                  image {  
                    id url url2x  
                    thumbnails {  
                      id bubbleMedium { id url url2x } 
                    } 
                     
                  }  
                } 
               
        
        abbreviation
      }
    } }
  
}
`;

export const EconomiesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(ECONOMIES_QUERY), 'allEconomies' ) }>{children}</Provider>;
}