import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, entityTypeNameFor } from '@shapeable/utils';
import { ExplorerEntityDetailsLayout, ExplorerProviders, useActivePerson } from '@shapeable/ui';
import { ExplorerNewsMainLayout } from './explorer-news-main-layout';
import { ExplorerNewsAsideLayout } from './explorer-news-aside-layout';
const cls = classNames('explorer-news-layout');

// -------- Types -------->

export type ExplorerNewsLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerNewsLayoutDefaultProps: Omit<ExplorerNewsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->


const ContainerStyles = breakpoints({
  base: css`
    background: ${theme.COLOR('bright')};
  `,
});

const LayoutStyles = breakpoints({
  base: css`

    .shp--explorer-entity-details-layout__banner-and-tabs {
      display: none;
    }
  `,
  desktop: css`
    
  `,
});

const ConversationsStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,

    NewsLayout: styled(ExplorerNewsMainLayout)`${ConversationsStyles}`,
};

export const ExplorerNewsLayout: Shapeable.FC<ExplorerNewsLayoutProps> = (props) => {
  const { className, children, entity } = props;
  
  return (
    <ExplorerProviders>

    <My.Container className={cls.name(className)}>
      <My.Layout
        asideMaxWidth={350}
        asideHeaderProps={{ navigationProps: { showIcon: false }}}
        entity={entity}
        isAsideSticky
        showAsideHeader={false}
        stickyTopOffset={120}
        asideIsRevealed={false}
        
        content={
          <My.NewsLayout entity={entity} />
        }

        metaHead={
          <ExplorerNewsAsideLayout entity={entity} />
        }

      />
      {children}
    </My.Container>
    </ExplorerProviders>
  )
};

ExplorerNewsLayout.defaultProps = ExplorerNewsLayoutDefaultProps;
ExplorerNewsLayout.cls = cls;